<template>
<div v-if="!loading" class="commentsWrapper">
    <div v-if="!Comments.length" class="bgPresDark mb-8 p-6 cPresent rounded-md">
        Bis jetzt wurde noch kein Update eingetragen.
    </div>
    <div v-else>
        <div v-for="c, index in Comments" :key="c.id" class="bgPresDark p-6 cPresent  mb-4 rounded-md">
            <div v-if="c.userID == $store.state.user.user.id" @click="openMod('deleteComment', index)" class="editBtnWrapper fxEnd">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="delCommentBtn">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>

            </div>
            <div class="fyCenter justify-between  w-full">
                <router-link class="fyCenter my-1" :to="'user/userById/'+c.userID">
                    <get-avatar class="mr-2" type="small" form="round" :url="getAvUrl(c.userID)" />
                    <span v-html="getUserName(c.userID)"></span>
                </router-link>
                <div class="timeWrapper">
                    {{c.Time}} Uhr
                </div>
            </div>
            <div class="mt-4 bgCamp rounded-md p-6 ">
                {{c.Comment}}
            </div>

        </div>
    </div>
    <div class="newUpdate mb-8">
        <textarea v-model="newComment" class="bgPresDark p-6 cPresent  w-full rounded-md border bWhite" id="" cols="30" rows="5"></textarea>
        <div @click="sendComment()" class="btnPrime mt-4 small">
            Abschicken
        </div>
    </div>
    <div :class="['fspModal contModal', modOpen == 'deleteComment' ? 'activeMod' : '']">
        <div class="modal-cont-sm text-center">
            <div class="fxEnd -mt-5">
                <div @click="openMod(false)" class="closeBtn">X</div>
            </div>
            <div class="modalAddHeading">Willst du das Update wirklich löschen?</div>
            <div class="textInputM">
                <div @click="deleteComment()" class="btnOutline mt-4 smaller">
                    Löschen
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { getComments, getUsers } from '@/graphql/queries'
import { updateFileComments } from '@/graphql/mutations';
import formatDate from '@/composables/formats';
import getAvatar from '../user/getAvatar.vue';

export default {
    components: { getAvatar },
    props: {
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            Comments: [],
            users: [],
            newComment: '',
            delIndex: null,
            loading: true,
            modOpen: false
        }
    },
    apollo: {
        fspfile: {
            query: getComments,
            prefetch: true,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            variables() {
                return {
                    "id": this.id
                }
            },
            result(res) {
                if (res.data.fspfile.data.attributes.Comments !== null) {
                    this.Comments = res.data.fspfile.data.attributes.Comments
                } else {
                    this.Comments = [];
                }

            }
        },
        usersPermissionsUsers: {
            query: getUsers,
            prefetch: true,
            context: {
                headers: {
                    "Authorization": 'Bearer ' +  sessionStorage.getItem('jwt')
                },
            },
            result(res) {
                this.users = res.data.usersPermissionsUsers.data;
                this.loading = false;
            }
        },

    },
    methods: {
        sendComment() {
            if (this.newComment != '') {
                let nc = {
                    "userID": this.$store.state.user.user.id,
                    "Time": formatDate(new Date(), 'de_time'),
                    "Comment": this.newComment
                }
                var nArr = [...this.Comments];
                nArr.push(nc);

                this.updateComments(nArr);

            }
        },
        updateComments(comments) {
            this.$apollo.mutate({
                mutation: updateFileComments,
                context: {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                },
                variables: {
                    "id": this.id,
                    "data": {
                        "Comments": comments
                    }

                }
            }).then((res) => {
                this.Comments = res.data.updateFspfile.data.attributes.Comments;
                this.newComment = '';
                this.delIndex = null;
            });
        },
        getUserName(cid) {
            let did = cid.toString();
            return this.users.find(x => x.id === did).attributes.Firstname + ' ' + this.users.find(x => x.id === did).attributes.Lastname;
        },
        getAvUrl(cid) {
            let did = cid.toString();
            console.log(this.users);
            return this.users.find(x => x.id == did).attributes.Avatarurl;
        },
        openMod(state, index = null) {
            this.delIndex = index;
            this.modOpen = state;
            console.log(this.modOpen);
            this.$store.dispatch('openMod', state);
        },
        deleteComment() {
            var nArr = [...this.Comments];
            nArr.splice(this.delIndex, 1);
            this.updateComments(nArr);
            this.openMod(false);
        }
    }
}
</script>
