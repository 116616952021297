<template>
<div class="notifyWrapper">
    <div class="">
        <div class="secInd inline-block">
            BENACHRICHTIGEN
        </div>
    </div>
</div>
<div class="row  ">
    <div class="col-md-6">
        <div class="my-6">
            <div class="label">
                Wähle die Departments
            </div>
            <vue-multiselect v-model="cDeps" :taggable="false" track-by="id" @select="updateDepUser" :multiple="true" label="name" placeholder="Departments auswählen" :options="sDepts"></vue-multiselect>

        </div>
    </div>
    <div class="col-md-6">
        <div class="my-6">
            <div class="label">
                Wähle die Rezipienten
            </div>
            <vue-multiselect v-model="cUsers" :taggable="false" track-by="id" :multiple="true" label="name" placeholder="Nutzer auswählen" :options="users"></vue-multiselect>
        </div>
    </div>
    <div class="col-md-6">
        <div class="my-6">
            <div class="label">
                Wähle die Dateien
            </div>
            <vue-multiselect v-model="cFiles" :taggable="false" track-by="id" :multiple="true" label="name" placeholder="Dateien auswählen" :options="sFiles"></vue-multiselect>
        </div>
        <div @click="sendMail" class="btnOutline mt-6">
            TESTMAIL
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import DepartmentList from '../components/meta/departmentList.vue';
import { getFilesNameId, getUsers, getDepartmentsUser } from '@/graphql/queries'
import VueMultiselect from 'vue-multiselect'
import notifyMail from '@/composables/mailing';

export default {
    components: {
        DepartmentList,
        VueMultiselect
    },
    data() {
        return {
            cFiles: [],
            sFiles: [],
            cUsers: [],
            users: [],
            sDepts: [],
            cDeps: []
        }
    },
    apollo: {
        fspfiles: {
            query: getFilesNameId,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                res.data.fspfiles.data.forEach(f => {
                    let cFile = {
                        "id": f.id,
                        "name": f.attributes.Name
                    }
                    this.sFiles.push(cFile)
                });
            }
        },
        usersPermissionsUsers: {
            query: getUsers,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                res.data.usersPermissionsUsers.data.forEach(f => {
                    let cFile = {
                        "id": f.id,
                        "name": f.attributes.username,
                        "mail": f.attributes.email
                    }
                    this.users.push(cFile)
                });
            }
        },
        departments: {
            query: getDepartmentsUser,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            prefetch: true,
            result(res) {
                res.data.departments.data.forEach(f => {
                    let cDep = {
                        "id": f.id,
                        "name": f.attributes.Name,
                        "user": []
                    }
                    if (f.attributes.users) {
                        f.attributes.users.data.forEach(u => {
                            let cFile = {
                                "id": u.id,
                                "name": u.attributes.username,
                                "mail": u.attributes.email,
                            }
                            cDep.user.push(cFile)
                        });
                    }
                    this.sDepts.push(cDep)
                });
            }
        },
    },
    methods: {
        sendMail() {
            let data = {
                rec: this.cUsers,
                file: this.cFiles
            };  
            console.log(data);
            notifyMail(data);
            // var params = new URLSearchParams();
            // params.append('mail', 'on');
            // params.append('data', sData);

            // axios.post("https://fspassets.brotsalz.de/mailing/mailer.php", params)
        },
        updateDepUser(item) {
            let nItems = [...this.cDeps];
            nItems.push(item);
            let nUsers = [];

            nItems.forEach(f => {
                f.user.forEach(u => {
                    nUsers.push(u);
                });
            });

            let tArray = this.cUsers.concat(nUsers);

            const uniqueIds = [];

            let unique = tArray.filter(element => {
                let isDuplicate = uniqueIds.includes(element.id);

                if (!isDuplicate) {
                    uniqueIds.push(element.id);

                    return true;
                }

                return false;
            });

            this.cUsers = unique;

        }
    }
}
</script>
