import { createStore } from 'vuex'
import axios from 'axios'


export default createStore({
  state: {
    user: null,
    isNewUser: true,
    modOpen: false,
    cTheme: false,
    canUpload: false,
        
    // Upload A File 
    cContainers: 1,
    cDepartments: [],
    cChans: [],
    cMeds: [],
    cMa: [],
    cTags: [],
    //

    mobileMenu: false
  },
  mutations: {
    SET_USER_DATA (state, userData) {
      sessionStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common['Authorization'] = `Bearer ${
        userData.token
      }`
      state.user = userData
      state.cTheme = userData.user.cTheme
      state.canUpload = userData.user.canUpload
      
    },
    SET_USER_INFO(state, userInfo) {
      var userData = JSON.parse(sessionStorage.getItem('user'));
      var newInfo = updateUserInfo(userData.user, userInfo);
      userData.user = newInfo;
      sessionStorage.setItem('user', JSON.stringify(userData))
      state.user = userData
      
    },
    SET_USER_AVATAR(state, avatarUrl){
      var userData = JSON.parse(sessionStorage.getItem('user'));
      userData.user.Avatarurl = avatarUrl;
      sessionStorage.setItem('user', JSON.stringify(userData));
      state.user = userData
    },
    LOGOUT () {
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('jwt')
      location.reload()
    },
    IS_NEW_USER (state, isNewUser) {
      state.isNewUser = isNewUser
    },
    OPEN_MODAL(state, openMod) {
      state.modOpen = openMod
    },
    TOGGLE_THEME(state, cTheme) {
      state.cTheme = cTheme;
    },

    // Mutate Upload File //
    UPLOAD_TOGGLE_CONTAINER(state, data) {
      state.cContainers = data;
    },
    UPLOAD_TOGGLE_DEPARTMENTS(state, data) {
      state.cDepartments = data;
    },
    UPLOAD_TOGGLE_CHANS(state, data) {
      state.cChans = data;
    },
    UPLOAD_TOGGLE_TAGS(state, data) {
      state.cTags = data;
    },
    UPLOAD_TOGGLE_MATERIALS(state, data) {
      state.cMa = data;
    },
    UPLOAD_TOGGLE_MEDIUMS(state, data) {
      state.cMeds = data;
    },
    CLEAR_UPLOAD_STATES(state) {
      state.cContainers = 1;
      state.cDepartments = [];
      state.cChans = [];
      state.cMeds = [];
      state.cTags = [];
      state.cMa = [];
    },
    OPEN_MOBILE_MENU(state, data) {
      state.mobileMenu = data;
    }
  },
  actions: {
    login ({ commit }, credentials) {
      return axios
        .post("https://fspback.brotsalz.de/api/auth/local", credentials)
        .then(({ data }) => {
          console.log(data);
          commit('SET_USER_DATA', data)
          return data;
        })
    },
    updateUser({commit}, userInfo) {
      commit('SET_USER_INFO', userInfo)
    },
    updateAvatar({commit}, avatarUrl) {
      commit('SET_USER_AVATAR', avatarUrl)
    },
    logout ({ commit }) {
      commit('LOGOUT')
    },
    isNewUser ({ commit }, isNewUser) {
      commit('IS_NEW_USER', isNewUser)
    },
    openMod({commit}, openMod) {
      commit('OPEN_MODAL', openMod)
    },
    toggleTheme({commit}, cTheme) {
      commit('TOGGLE_THEME', cTheme)
    },

    // UPLOAD FILES //

    updateToggleConts({commit}, cont) {
      commit('UPLOAD_TOGGLE_CONTAINER', data);
    },
    updateToggleDeps({commit}, dep) {
      let data = this.state.cDepartments;
      if(data.includes(dep)) {
        data.splice(data.indexOf(dep), 1);
      } else {
        data.push(dep);
      }
      commit('UPLOAD_TOGGLE_DEPARTMENTS', data);
    },
    updateToggleChans({commit}, chan) {
      let data = this.state.cChans;
      if(data.includes(chan)) {
        data.splice(data.indexOf(chan), 1);
      } else {
        data.push(chan);
      }
      commit('UPLOAD_TOGGLE_CHANS', data);
    },
    updateToggleMeds({commit}, med) {
      let data = this.state.cMeds;
      if(data.includes(med)) {
        data.splice(data.indexOf(med), 1);
      } else {
        data.push(med);
      }
      commit('UPLOAD_TOGGLE_MEDIUMS', data);
    },
    updateToggleMats({commit}, mat) {
      let data = this.state.cMa;
      if(data.includes(mat)) {
        data.splice(data.indexOf(mat), 1);
      } else {
        data.push(mat);
      }
      commit('UPLOAD_TOGGLE_MATERIALS', data);
    },
    updateToggleTags({commit}, tag) {
      let cTags = this.state.cTags;
      if(cTags.includes(tag)) {
       cTags.splice(cTags.indexOf(tag), 1);
      } else {
        cTags.push(tag);
      }
      commit('UPLOAD_TOGGLE_TAGS', cTags);
    },
    clearUploadStates({commit}) {
      commit('CLEAR_UPLOAD_STATES');
    },
    openMobileMenu({commit}, state) {
      commit('OPEN_MOBILE_MENU', state);
    }
  
  }
})


function updateUserInfo(target, src) {
  const res = {};
  Object.keys(target)
        .forEach(k => res[k] = (src[k] ?? target[k]));
  return res;
}
