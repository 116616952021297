<template>
<div class="fileTileContWrapper">
    <div class="fileTilePrev">
        <div class="" v-if="isFile(f.attributes.fspFile.data.attributes.ext) == 'pdf'">
            <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
                <g id="Group_2481" data-name="Group 2481" transform="translate(-928.5 -6727.5)">
                    <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.721,7.721,0,0,0-2.261-5.458L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.5 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                    <path id="Path_13180" fill="currentColor" data-name="Path 13180" d="M2.88-24.012V0H6.552V-9.756H8.208a8.668,8.668,0,0,0,5.868-1.872,6.725,6.725,0,0,0,2.34-5.256,6.95,6.95,0,0,0-2.988-5.832,9.384,9.384,0,0,0-5.256-1.3Zm3.672,3.384h1.62c4.176,0,4.716,2.376,4.716,3.708,0,2.232-1.512,3.78-4.68,3.78H6.552ZM20.16-24.012V0h4.968c3.348,0,5.976-.324,8.784-2.3a11.45,11.45,0,0,0,4.824-9.684,11.886,11.886,0,0,0-4.608-9.684c-2.664-1.98-5.58-2.34-8.928-2.34Zm3.672,3.456h1.584A10.757,10.757,0,0,1,31.824-18.9a8.556,8.556,0,0,1,3.24,6.912,8.466,8.466,0,0,1-3.276,6.84,10.4,10.4,0,0,1-6.372,1.692H23.832Zm31.536-3.456H43.344V0h3.672V-11.016H55.08v-3.456H47.016v-6.084h8.352Z" transform="translate(956 6811)" />
                </g>
            </svg>
        </div>
        <div class="prevImgData" v-if="isFile(f.attributes.fspFile.data.attributes.ext) == 'doc'">
            <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
                <g id="Group_2481" data-name="Group 2481" transform="translate(-928.055 -6727.5)">
                    <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.055 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                    <path id="Path_13181" fill="currentColor" data-name="Path 13181" d="M2.88-24.012V0H7.848c3.348,0,5.976-.324,8.784-2.3a11.45,11.45,0,0,0,4.824-9.684,11.886,11.886,0,0,0-4.608-9.684c-2.664-1.98-5.58-2.34-8.928-2.34Zm3.672,3.456H8.136A10.757,10.757,0,0,1,14.544-18.9a8.556,8.556,0,0,1,3.24,6.912,8.466,8.466,0,0,1-3.276,6.84A10.4,10.4,0,0,1,8.136-3.456H6.552ZM37.4-24.444A12.181,12.181,0,0,0,24.912-11.988,12.181,12.181,0,0,0,37.4.468,12.181,12.181,0,0,0,49.9-11.988,12.181,12.181,0,0,0,37.4-24.444Zm0,21.528c-5.148,0-8.82-3.744-8.82-9.072s3.672-9.072,8.82-9.072,8.82,3.744,8.82,9.072S42.552-2.916,37.4-2.916Zm34.884-19.8a11.411,11.411,0,0,0-6.408-1.728A11.891,11.891,0,0,0,57.2-21.312a12.564,12.564,0,0,0-3.852,9.4A11.773,11.773,0,0,0,57.2-2.844,12.029,12.029,0,0,0,65.7.468a13.1,13.1,0,0,0,6.588-1.8V-5.688a9.529,9.529,0,0,1-6.48,2.772,8.669,8.669,0,0,1-8.784-9c0-5.292,3.528-9.144,8.748-9.144A9.362,9.362,0,0,1,72.288-18.4Z" transform="translate(946.676 6811.444)" />
                </g>
            </svg>
        </div>
        <div class="prevImgData" v-if="isFile(f.attributes.fspFile.data.attributes.ext) == 'tiff'">
            <svg xmlns="http://www.w3.org/2000/svg" width="144.657" height="143" viewBox="0 0 144.657 143">
                <g id="Group_2482" data-name="Group 2482" transform="translate(-2835.172 -6144.5)">
                    <path id="Path_13182" data-name="Path 13182" d="M4,108.25,43.841,68.409a17.375,17.375,0,0,1,24.568,0L108.25,108.25M90.875,90.875,104.653,77.1a17.375,17.375,0,0,1,24.568,0L143,90.875M90.875,38.75h.087M21.375,143h104.25A17.375,17.375,0,0,0,143,125.625V21.375A17.375,17.375,0,0,0,125.625,4H21.375A17.375,17.375,0,0,0,4,21.375v104.25A17.375,17.375,0,0,0,21.375,143Z" transform="translate(2834 6142.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                    <path id="Path_13184" fill="currentColor" data-name="Path 13184" d="M15.048-20.556v-3.456H.36v3.456H5.868V0H9.54V-20.556Zm3.24-3.456V0H21.96V-24.012Zm21.456,0H27.72V0h3.672V-11.016h8.064v-3.456H31.392v-6.084h8.352Zm16.2,0H43.92V0h3.672V-11.016h8.064v-3.456H47.592v-6.084h8.352Z" transform="translate(2851 6189)" />
                </g>
            </svg>
        </div>
        <div class="prevImgData" v-if="isFile(f.attributes.fspFile.data.attributes.ext) == 'movie'">
            <svg xmlns="http://www.w3.org/2000/svg" width="112.111" height="143" viewBox="0 0 112.111 143">
                <g id="Group_2485" data-name="Group 2485" transform="translate(-2675 -6364)">
                    <g id="Group_2481" data-name="Group 2481" transform="translate(1746.945 -363.5)">
                        <path id="Path_13179" data-name="Path 13179" d="M20.444,142H97.667a15.444,15.444,0,0,0,15.444-15.444V52.53a7.72,7.72,0,0,0-2.26-5.457L69.04,5.263A7.722,7.722,0,0,0,63.581,3H20.444A15.444,15.444,0,0,0,5,18.444V126.556A15.444,15.444,0,0,0,20.444,142Z" transform="translate(925.055 6726.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                    </g>
                    <path id="Path_13187" data-name="Path 13187" d="M55.2,7.5V37.947M37.8,7.5V37.947M3,34.5V10.951A3.262,3.262,0,0,1,7.88,8.118L28.492,19.893a3.262,3.262,0,0,1,0,5.666L7.88,37.335A3.262,3.262,0,0,1,3,34.5Z" transform="translate(2701.5 6416)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                </g>
            </svg>
        </div>
        <div class="prevImgData" v-if="isFile(f.attributes.PrevLink) != ''">
            <img :src="f.attributes.PrevLink" alt="">
        </div>
        <div class="prevImgData" v-else-if="isFile(f.attributes.fspFile.data.attributes.ext) == 'img'">
            <img :src="f.attributes.fspFile.data.attributes.url " alt="">
        </div>
    </div>

</div>
</template>

<script>
import isFileType from '@/composables/formatType'

export default {
    props: {
        f: {
            type: Object
        }
    },
    data() {
        return {

        }
    },
    methods: {
        isFile(type) {
            console.log(this.f);
            return isFileType(type);
        },
    }
}
</script>

