<template>
<div class="listRecentContainer w-full">
    <div v-if="!loading">
        <div class="fileList  w-full">
            <div class="fileListHeadRow fileListRow">
                <div class="fileTileCol label">
                    Name
                </div>
                <div class="fileDateCol label">
                    Datum
                </div>
                <div class="fileSizeCol label">
                    Größe
                </div>
                <div class="fileActionCol label">
                    Aktion
                </div>
            </div>
            <div v-for="f in fspfiles.data" :key="f.id" class="fileListRow fileListBodyRow">
                <div class="listRecentColFilePrev">
                    <router-link :to="'files/showFileById/'+f.id">
                        <img src="../../assets/icon/fileIcon.svg" alt=""> {{f.attributes.Name}}
                    </router-link>
                </div>
                <div class="fileDateCol">
                    {{ formatDate(f.attributes.updatedAt)}}
                </div>
                <div class="fileSizeCol">
                    {{fSize(f.attributes)}}
                </div>
                <div class="fileActionCol">
                    <img src="../../assets/icon/action.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    listRecent
} from '@/graphql/queries';

import axios from "axios";

import formatDate from '@/composables/formats'
import fileSizing from '@/composables/formatSize';

export default {
    apollo: {
        fspfiles: {
            query: listRecent,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + sessionStorage.getItem('jwt')
                },
            },
            result() {
                this.loading = false;
            }
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        prettyBytes(size) {
            console.log(size);
            return prettyBytes(size);
        },
        formatDate(date) {
            return formatDate(date, 'de');
        },
        fSize(fatts) {
            console.log(fatts);
            if (fatts.fspFile.data != undefined) {
                return fileSizing(fatts.fspFile.data.attributes.size);
            } 
            else if(fatts.fspFileV2 != null) {
                return fileSizing(fatts.fspFileV2.size);
            } else {
                console.log(fatts.fspFileLink);
                return 'Unbekannt';
            }
        },
    }
}
</script>
