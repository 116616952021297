const aws = require('aws-sdk')

import axios from 'axios';


aws.config.update({
    apiVersion: 'latest',
    credentials: {
        secretAccessKey: process.env.VUE_APP_AWS_ACCESS_SECRET,
        accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
    },
    region: process.env.VUE_APP_AWS_REGION,

})



export const s3 = new aws.S3({
    signatureVersion: 'v4',
})

export const singleUpload = (file, folder) => {
    const key = folder + Date.now() + '-' + file.name.replace(/\s/g, '-')
    const params = {
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Key: key,
        Expires: 60*60,
        ContentType: file.type,
    }
    const url = s3.getSignedUrl('putObject', params);
    return axios
        .put(url, file, {transformRequest: (data, headers) => {
            delete headers.common['Authorization'];
            return data;
          } 
        })
        .then(result => {
            const bucketUrl = decodeURIComponent(result.request.responseURL).split(
                key
            )[0]
            result.key = key
            result.fullPath = bucketUrl + key
            return result
        })
        .catch(err => {
            // TODO: error handling
            console.log(err)
        })
}

export const deleteObjectByKey = key => {
    const params = {
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Key: key,
    }
    const data = s3.deleteObject(params).promise()

    return data
}

export const getObjectByKey = key => {
    const params = {
        Bucket: process.env.VUE_APP_AWS_BUCKET,
        Key: key,
    }
s3.getObject(params, function(err, data) {
    // Handle any error and exit
    if (err)
        return err;

  // No error happened
  // Convert Body from a Buffer to a String

  console.log(data);
});

}